<template>
    <div class="flex justify-center items-center h-screen bg-gray-200 px-6">
      <div class="p-6 max-w-sm w-full bg-white shadow-md rounded-md">
        <div class="flex justify-center items-center">
          <h2 class="text-gray-700 font-semibold text-2xl">Cambia tu contraseña</h2>
        </div>
        <ResetPasswordForm class="mt-4"/>
      </div>
	</div>
</template>

<script>
  import ResetPasswordForm from "@/components/auth/ResetPasswordForm"

  export default {
    name: "ForgotPasswordView",
    components: {
      ResetPasswordForm
    },
  }
</script>