<template>
    <Form 
    @submit="resetPassword"
    :validation-schema="schema"
    class="relative"
    >
    <div class="grid grid-cols-1 gap-6 mt-4 text-gray-700 text-xl">
      <div class="relative text-left">
        <label for="email"> Correo: </label>
        <Field
          type="email"
          name="email"
          class="form-input w-full mt-2 rounded-md text-2xl border-gray-500"
          v-model="email"
          placeholder="luke@jedi.com"
        />
        <ErrorMessage name="email" class="absolute text-right text-sm text-red-600 inset-x-0 -bottom-5"/>
      </div>
      <div class="relative text-left">
        <label for="password"> Contraseña nueva: </label>
        <Field
          type="password"
          name="password"
          class="form-input w-full mt-2 rounded-md text-2xl border-gray-500"
          v-model="password"
          placeholder="•••••••"
        />
        <ErrorMessage name="password" class="absolute text-right text-sm text-red-600 inset-x-0 -bottom-5"/>
      </div>
      <div class="relative text-left">
        <label for="password_confirm"> Confirmar contraseña: </label>
        <Field
          type="password"
          name="password_confirm"
          class="form-input w-full mt-2 rounded-md text-2xl border-gray-500"
          v-model="password_confirmation"
          placeholder="•••••••"
        />
        <ErrorMessage name="password_confirm" class="absolute text-right text-sm text-red-600 inset-x-0 -bottom-5"/>
      </div>
    </div>
    <div class="flex justify-center mt-4">
      <BaseBtn
        type="submit"
        text="Cambiar contraseña"
        btnClass="py-3 px-4 text-center bg-blue-700 rounded-md w-full text-white text-xl hover:bg-blue-600 mt-2"
      />
    </div>
  </Form>
</template>
<script>
import AuthService from "@/services/AuthService"
import BaseBtn from "@/components/BaseBtn"
import { Field, Form, ErrorMessage } from "vee-validate"
import { object, string, ref } from "yup"

export default {
  name: "ResetPasswordForm",
  setup() {
    const schema = object({
      email: string().required("El correo es obligatorio.").email("Ingresa un correo válido."),
      password: string().required("Debes ingresar una nueva contraseña.").min(8, "Debe tener al menos 8 caractéres."),
      password_confirm: string().required("Debes confirmar la contraseña.").oneOf([ref('password'), null], "Las contraseñas deben coincidir.")
    })

    return {
      schema
    }
  },
  data(){
    return {
      emial: "",
      password: "",
      password_confirmation: ""
    }
  },
  methods: {
    resetPassword() {
      let payload = {
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation,
        token: this.$route.query.token
      }

      AuthService.resetPassword(payload)
        .then(() => {
          this.emitter.emit("notification-set", {
            type: 'success',
            title: "Contraseña cambiada",
            text: "Se ha realizado tu cambio de contraseña. ¡No la olvides!",
            time: 10000,
            group: 'notifications'
          })
        })
    }
  },
  components: {
    BaseBtn,
    Field,
    Form,
    ErrorMessage
  }
}
</script>